<template lang="pug">
  section#use.section.use
    div.container
      div.use__box
        div.use__img
          div.use_img-inner
            img(src="~@/assets/images/use.png" alt="Use image")
        div.use__content.content
          h2.h2.use__title How can you use F4.TO?
          div.text.use__text
            p The service has almost unlimited application areas and is an ideal alternative for more convenient communication and internet navigation.
            ul
              li Social media and blogs. Forget about ugly links in your posts.
              li Email marketing. Create branded links with click-through analytics.
              li Advertising campaigns. Link shortening with QR codes.

</template>

<script>

export default {
  name: 'UseBlock'
}

</script>
